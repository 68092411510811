<template>
    <div>
        <b-row>
            <b-col sm="5">
                <b-card>
                    <b-row>
                        <b-col cols="12" class="my-1">
                            <h5>CANTIDAD DE FORMULARIOS POR ESTADO</h5>    
                        </b-col>                    
                        <b-col sm="12">
                            <b-row class="text-center">
                                <b-col sm="4" md="2" class="my-auto">                                    
                                    <feather-icon icon="CalendarIcon" size="1.5x" class="mr-1" style="color: rgba(216, 164, 0, 0.85);"  /> De
                                </b-col>
                                <b-col sm="8" md="3" class="my-auto">                                    
                                    <flat-pickr
                                        v-model="starDateQuarter"
                                        class="form-control"
                                        :config="configCalendar"
                                    />        
                                </b-col>
                                <b-col sm="4" md="1" class="my-auto"> 
                                    a
                                </b-col>
                                <b-col sm="8" md="3" class="my-auto">
                                    <flat-pickr
                                        v-model="endDateQuarter"
                                        class="form-control"
                                        :config="configCalendar"
                                    />        
                                </b-col>    
                                <b-col sm="12" md="3" class="my-auto"> 
                                   <b-button
                                        size="sm"
                                        variant="primary"
                                        @click="filterQuarter"
                                    >                                    
                                     <feather-icon icon="FilterIcon" size="1x"/>   
                                       Filtrar
                                   </b-button>
                                </b-col>                            
                            </b-row>                            
                        </b-col>
                        <b-col cols="12">
                            <vue-apex-charts
                                ref="pieQuarterRef"
                                type="pie"
                                height="325"
                                class="mt-2 mb-1"
                                :options="tablePieQuarter.chartOptions"
                                :series="tablePieQuarter.series"
                            />                            
                        </b-col>                    
                    </b-row>
                </b-card>                                           
            </b-col>
            <b-col sm="7">  
                <b-card>                     
                    <json-excel :data="quarterTableExport()" name="cantidad_formularios_por_estado.xls"></json-excel>
                    <b-table
                        ref="table-quarter-ref"
                        :fields="fieldsQuarter"
                        :items="itemsQuarter"
                        show-empty
                        empty-text="No se encontraron registros coincidentes"
                    >   
                             
                        <template #cell(state_form_name)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.state_form_name }}</b>
                            <span v-else>{{ data.item.state_form_name }}</span>                         
                        </template>
                    
                        <template #cell(quantity)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.quantity }}</b>           
                            <span v-else>{{ data.item.quantity }}</span>
                        </template>
                        <template #cell(percentage)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.percentage }}</b>                       
                            <span v-else>{{ data.item.percentage }}</span>
                        </template>
                    
                    </b-table>
                </b-card>                
            </b-col>
        </b-row>
    </div>  
</template>

<script>

    import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es.js';
    import flatPickr from 'vue-flatpickr-component';
    import VueApexCharts from 'vue-apexcharts';
    import JsonExcel from "./download-excel";
    import axios from '@axios';

    export default {
        components: {
            flatPickr,
            VueApexCharts,
            JsonExcel
        },
        data() {
            return {
                starDateQuarter: "",
                endDateQuarter: "",
                
                configCalendar: {
                    locale:  SpanishLocale,
                    dateFormat: 'd-m-Y'
                },
                       
                fieldsQuarter: [
                    {key: 'index', label: "#"},
                    {key: "form_state_name", label: "ESTADO DEL FORMULARIO"},
                    {key: "quantity", label: "Cantidad"},
                    {key: "percentage", label: "%"}
                ],
                itemsQuarter: [],
              
                tablePieQuarter: {
                    series: [],
                    chartOptions: {
                        chart: {
                            toolbar: {
                                show: false,
                            }
                        },
                        labels: [],
                        dataLabels: {
                            enabled: true
                        },
                        legend: { show: false },
                        stroke: {
                            width: 4
                        },
                        noData: { text: "Sin datos..." }
                    }
                },
            }
        },
        beforeMount() {
            this.getDataTableQuarter();
        },
        methods: {           
            async getDataTableQuarter (){

                let response = await axios.get(`/reporteria/states-forms?filterStartDate=${this.starDateQuarter}&filterEndDate=${this.endDateQuarter}`);
                
                let total = 0;
                response.data.map(item => { total = total + item.forms_count } );
                                
                this.itemsQuarter = [];
                this.tablePieQuarter.series = [];
                let labels = [];

                response.data.map( (item, index) => {

                    this.itemsQuarter.push({
                        index: index + 1,
                        form_state_name: item.name,
                        quantity: item.forms_count,
                        percentage: ((item.forms_count*100)/total).toFixed(2) + '%'
                    });

                    this.tablePieQuarter.series.push((item.forms_count*100)/total);
                    labels.push(item.name);

                });

                this.itemsQuarter.push({
                    index: '',
                    form_state_name: 'TOTAL',
                    quantity: total,
                    percentage: '100%',
                    status: true
                });
                
                this.totalQuarter = total;
                this.tablePieQuarter.chartOptions.labels = labels;
                await this.$refs["pieQuarterRef"].refresh();

            },
            async filterQuarter (){
                await this.getDataTableQuarter();
                this.$refs['table-quarter-ref'].refresh();
            },            
            quarterTableExport (){

                let array = [];

                for (let a=0; a<this.itemsQuarter.length; a++)
                {
                    let objBody = {
                        'ITEM': a+1,
                        'ESTADO DEL FORMULARIO': this.itemsQuarter[a]["state_form_name"],
                        'CANTIDAD': this.itemsQuarter[a]["quantity"],
                        'PORCENTAJE':  this.itemsQuarter[a]["percentage"]
                    };

                    array.push(objBody);
                }
                
                array.splice(array.length-1, 1);

                let objFoot = {
                    'ITEM': '',
                    'ESTADO DEL FORMULARIO': 'TOTAL',
                    'CANTIDAD': this.totalQuarter,
                    'PORCENTAJE': '100%'
                };

                array.push(objFoot);
                
                return array;
            },          
        }
    }

</script>

<style scoped>

    input[type=text] {
        border: none;
        border-radius: 0px;
        background: transparent;
        border-bottom: 1px solid #d8d6de;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/chart-apex.scss';
</style>
